import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../../Components/Separator/BMXSeparator";

const SettingsSecretaryFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div className="justify-content-start">
      <Row>
        <Col>
          <BMXSeparator title={t("global:settings.securityReceivedMail")} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="EMAIL_SERVICE_ESTIMATES_HOST"
            name="EMAIL_SERVICE_ESTIMATES_HOST.value"
            component={BMXTextField}
            label={t("global:settings.EMAIL_SERVICE_ESTIMATES_HOST")}
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="EMAIL_SERVICE_ESTIMATES_PORT"
            name="EMAIL_SERVICE_ESTIMATES_PORT.value"
            component={BMXTextField}
            label={t("global:settings.EMAIL_SERVICE_ESTIMATES_PORT")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="EMAIL_SERVICE_ESTIMATES_LOGIN"
            name="EMAIL_SERVICE_ESTIMATES_LOGIN.value"
            component={BMXTextField}
            label={t("global:settings.EMAIL_SERVICE_ESTIMATES_LOGIN")}
          />
        </Col>
        <Col>
          <Field
            data-cy="EMAIL_SERVICE_ESTIMATES_PASS"
            name="EMAIL_SERVICE_ESTIMATES_PASS.value"
            type="password"
            component={BMXTextField}
            label={t("global:settings.EMAIL_SERVICE_ESTIMATES_PASS")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BMXSeparator title={t("global:settings.securitySendMail")} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="SMTP_SERVER"
            name="EMAIL_SERVICE_ESTIMATES_SMTP_HOST.value"
            component={BMXTextField}
            label={t("global:settings.SMTP_SERVER")}
          />
        </Col>
        <Col>
          <Field
            data-cy="SMTP_PORT"
            name="EMAIL_SERVICE_ESTIMATES_SMTP_PORT.value"
            component={BMXTextField}
            label={t("global:settings.SMTP_PORT")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            data-cy="EMAIL_FROM"
            name="EMAIL_SERVICE_ESTIMATES_SMTP_EMAIL_FROM.value"
            component={BMXTextField}
            label={t("global:settings.EMAIL_FROM")}
          />
        </Col>
        <Col>
          <Field
            data-cy="SMTP_LOGIN"
            name="EMAIL_SERVICE_ESTIMATES_SMTP_LOGIN.value"
            component={BMXTextField}
            label={t("global:settings.SMTP_LOGIN")}
          />
        </Col>
        <Col>
          <Field
            data-cy="SMTP_PASS"
            name="EMAIL_SERVICE_ESTIMATES_SMTP_PASS.value"
            component={BMXTextField}
            type="password"
            label={t("global:settings.SMTP_PASS")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SettingsSecretaryFormStepOne;
